import React, { useContext } from 'react';
import './CartItem.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/remove_icon.png';

const CartItem = () => {
    const { getTotalCartAmount, all_product, cartItems, removeCart } = useContext(ShopContext);
    const totalAmount = getTotalCartAmount();

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        const res = await loadRazorpayScript();

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // Fetch the order from your backend
        const orderData = await fetch("/create-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount: totalAmount }),
        }).then((t) => t.json());

        const options = {
            key: "rzp_test_GS1EalEPfwGjq3", // Enter your test key ID here
            amount: orderData.amount,
            currency: orderData.currency,
            name: "Brilliant View",
            description: "Test Transaction",
            order_id: orderData.id,
            handler: function (response) {
                alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
            },
            prefill: {
                name: "Test User",
                email: "testuser@example.com",
                contact: "9999999999",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    return (
        <div className="cartitem">
            {/* Cart items display logic */}
            <div className="cartitem-down">
                <div className="cartitem-total">
                    <h1>Cart Total</h1>
                    <p>Total: ₹{totalAmount}</p>
                    <button onClick={handlePayment}>PROCEED TO CHECKOUT</button>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
