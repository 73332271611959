import React from 'react';
import './DescriptionBox.css'

const DescriptionBox = () => {
    return(
        <div className="descriptionbox">
            <div className="descriptionbox-navigator">
                <div className="descriptionbox-nav-box">Description</div>
                <div className="descriptionbox-nav-box fade">Review (122)</div>
            </div>
            <div className="descriptionbox-description">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores impedit harum optio, reiciendis magnam quidem officia? Veniam, quo facere eligendi quas soluta vel fugit.</p>

                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi, officia hic minus quos perspiciatis, voluptatum doloremque cupiditate iusto nemo expedita recusandae, libero velit eaque corrupti facilis aliquam provident nobis sapiente. Magnam temporibus sint, placeat nulla sunt ex qui at ab voluptatum asperiores maiores nam harum, cupiditate dolor voluptate officia ducimus optio. Molestiae corporis dicta debitis.</p>
            </div>
        </div>
    )
}

export default DescriptionBox