import React,{useEffect, useState} from "react"
import './Popular.css'
// import data_product from "../Assets/data"
import Item from "../Items/Item"

const Popular = () => {
    const [all_product,setNew_collection] =useState([]);

    useEffect(()=>{
        // fetch('http://localhost:4000/allproduct')
        //Railway setup
        fetch('http://brillantviewweb-production.up.railway.app/allproduct')
        .then((response) =>response.json())
        .then((data)=>setNew_collection(data))
    },[])

    return (
        <div className="popular">
            <h1>Popular</h1>
            <hr />
            <div className="popular-item">
                {all_product.map((item, i)=>{
                    return <Item  key={i}  id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
                })}
            </div>
        </div>
    )
}

export default Popular